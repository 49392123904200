import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import Link from "../../UI/Link";
import Icon from "../../UI/Icon";
import { Box, Heading, Flex, Button, Text } from "rebass/styled-components";

const ImgMarkupTwoCol = ({ data, slug }) => {
  return (
    <Wrapper data={data}>
      {data.title && (
        <Heading as="h2" variant="blockTitle">
          {data.title}
        </Heading>
      )}
      <Box>
        {data.rows.map((item, i) => (
          <Flex
            key={i}
            variant="grid"
            alignItems="center"
            flex="1"
            pt={[10, 10, 11]}
            flexDirection={
              (item.textPosition == "left" && ["column-reverse", "column-reverse", "row"]) ||
              (item.textPosition == "right" && ["column-reverse", "column-reverse", "row-reverse"])
            }
            sx={{
              "&:first-child": {
                pt: 0
              }
            }}
          >
            <Box
              width={[1, 1, 1 / 2]}
              variant="gridItem"
              pl={item.textPosition == "left" ? [4, 6, 8, 11] : [4, 6, 8, 11]}
              pr={item.textPosition == "right" ? [4, 6, 8, 11] : [4, 6, 8, 11]}
            >
              {item.contentTitle && (
                <Heading as="h2" variant="h2">
                  {item.contentTitle}
                </Heading>
              )}
              <Box
                mt={[4, 4, 6]}
                sx={{
                  "& > div > ul:last-child": {
                    mb: 0,
                    "li:last-child": {
                      mb: 0
                    }
                  },
                  "p:last-child": {
                    mb: 0
                  },
                  "h3, h4": {
                    "&:first-child": {
                      mt: 0
                    }
                  }
                }}
              >
                <Markdown>{item.content}</Markdown>
              </Box>
              {item.cta && (
                <Box mt={6}>
                  <Link href={item.cta.href}>
                    <Button variant="base">
                      <Text>{item.cta.label}</Text>
                      <Icon icon="arrow-small-next" size={14} />
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
            <Box width={[1, 1, 1 / 2]} variant="gridItem" pb={[4, 4, 0]}>
              <Box
                maxWidth="560px"
                maxHeight={item.patternImg ? "560px" : "auto"}
                ml="auto"
                mr="auto"
                pl={item.patternImg ? 5 : 0}
                pb={item.patternImg ? 4 : 0}
                sx={{
                  overflow: "hidden",
                  position: "relative",
                  "&:before, &:after": {
                    content: '""',
                    display: item.patternImg ? "block" : "none",
                    width: "70%",
                    position: "absolute",
                    left: 0,
                    bg: "primaryLight",
                    zIndex: 1
                  },
                  "&:before": {
                    height: "175px",
                    bottom: 0,
                    opacity: "0.6"
                  },
                  "&:after": {
                    height: "15px",
                    bottom: "190px",
                    opacity: "0.2"
                  },
                  "& > div": {
                    position: "relative",
                    zIndex: 2
                  }
                }}
              >
                <CustomImage img={item.image} />
              </Box>
            </Box>
          </Flex>
        ))}
      </Box>
    </Wrapper>
  );
};

ImgMarkupTwoCol.propTypes = {
  slug: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.any,
    rows: PropTypes.any
  })
};
export default ImgMarkupTwoCol;
