import PropTypes from "prop-types";
import React from "react";
import Section from "../UI/Section";
import Container from "../UI/Container";
import { Heading } from "rebass/styled-components";

const ParallaxWrapper = ({ parallaxActived, bgImage, children }) => {
  return <>{children}</>;
};

ParallaxWrapper.propTypes = {
  bgImage: PropTypes.any,
  children: PropTypes.any,
  parallaxActived: PropTypes.any
};

const Wrapper = ({ data, children, containerProps, sx, ...props }) => {
  containerProps = containerProps || {};
  const blockTitle = data.blockSettings
    ? data.blockSettings.showTitle && data.blockSettings.blockname
      ? data.blockSettings.blockname
      : false
    : false || false;
  const bgImg = data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : false
    : false || false;
  const bgImgUrl = bgImg
    ? typeof bgImg === "string"
      ? bgImg
      : bgImg.childImageSharp.fluid.src
    : "";
  const sectionProps = {};
  sx = sx || {};
  const fluidContainer = data.blockSettings ? data.blockSettings.containerFluid : false;
  const maxContainer = data.blockSettings ? data.blockSettings.maxContainer : false;
  const parallaxActived = data.blockSettings ? data.blockSettings.parallax : false;
  const fullHeightActived = data.blockSettings ? data.blockSettings.fullHeight : false;

  if (data.blockSettings) {
    sectionProps.id = data.blockSettings.id;
    sectionProps.bg = data.blockSettings.bg;
    sectionProps.color = data.blockSettings.color;
    if (data.blockSettings.align) {
      sectionProps.textAlign = data.blockSettings.align;
    }
    if (data.blockSettings.padding) {
      if (data.blockSettings.padding.bottom && data.blockSettings.padding.bottom.length) {
        sectionProps.pb = data.blockSettings.padding.bottom;
      }
      if (data.blockSettings.padding.top && data.blockSettings.padding.top.length) {
        sectionProps.pt = data.blockSettings.padding.top;
      }
    }
    sectionProps.sx = sx;
    if (bgImg) {
      sectionProps.sx = {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${bgImgUrl})`,
        ...sx
      };
    } else {
      const bgColor = data.blockSettings.bg || "white";
      let skewTop = false;
      let skewBottom = false;
      if (data.blockSettings.skews) {
        if (data.blockSettings.skews.skewTop && data.blockSettings.skews.skewTop) {
          skewTop = data.blockSettings.skews.skewTop;
          sectionProps.pt = skewTop ? [11, 10, 10, 12] : [12, 12, 13, 15];
        }
        if (data.blockSettings.skews.skewBottom && data.blockSettings.skews.skewBottom) {
          skewBottom = data.blockSettings.skews.skewBottom;
          sectionProps.pb = skewBottom ? [11, 10, 10, 12] : [12, 12, 13, 15];
        }
      }
      sectionProps.sx = {
        "&:before": {
          borderBottomColor: skewTop ? bgColor : "transparent",
          borderLeftWidth: skewTop == "skewTopRight" ? "100vw" : 0,
          borderRightWidth: skewTop == "skewTopLeft" ? "100vw" : 0
        },
        "&:after": {
          borderTopColor: skewBottom ? bgColor : "transparent",
          borderLeftWidth: skewBottom == "skewBottomRight" ? "100vw" : 0,
          borderRightWidth: skewBottom == "skewBottomLeft" ? "100vw" : 0
        },
        ...sx
      };
    }
  }
  if (fullHeightActived) {
    containerProps.minHeight = "100vh";
  }

  const finalProps = {
    ...sectionProps,
    ...props
  };
  if (parallaxActived) {
    finalProps.bg = "";
    finalProps.sx.backgroundImage = "";
    finalProps.sx.background = "";
    finalProps.sx.bg = "";
  }

  return (
    <ParallaxWrapper parallaxActived={parallaxActived} bgImage={bgImgUrl}>
      <Section {...finalProps} variant="skewBoth">
        <Container fluid={fluidContainer} maxContainer={maxContainer} {...containerProps}>
          {blockTitle !== false && (
            <Heading
              as="h2"
              variant="h2"
              textAlign="center"
              mb={[7, 8, 9, 10]}
              sx={{
                "&:after": {
                  mx: "auto"
                }
              }}
            >
              {blockTitle}
            </Heading>
          )}
          {children}
        </Container>
      </Section>
    </ParallaxWrapper>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
  containerProps: PropTypes.shape({
    minHeight: PropTypes.any
  }),
  data: PropTypes.shape({
    blockSettings: PropTypes.shape({
      align: PropTypes.any,
      bg: PropTypes.any,
      bgImg: PropTypes.any,
      blockname: PropTypes.string,
      color: PropTypes.any,
      containerFluid: PropTypes.any,
      maxContainer: PropTypes.any,
      fullHeight: PropTypes.any,
      id: PropTypes.any,
      skews: PropTypes.shape({
        skewTop: PropTypes.any,
        skewBottom: PropTypes.any
      }),
      padding: PropTypes.shape({
        bottom: PropTypes.any,
        top: PropTypes.any
      }),
      parallax: PropTypes.any,
      showTitle: PropTypes.any
    })
  }),
  sx: PropTypes.object
};

export default Wrapper;
