import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Box, Heading, Text, Button } from "rebass/styled-components";
import Link from "../../UI/Link";
import { withTheme } from "styled-components";

const Header = ({ data, theme }) => {
  const fullHeightActived = data.blockSettings ? data.blockSettings.fullHeight : false;
  const bgImg = data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : ""
    : "" || "";
  data.ctas = data.ctas || [];

  const bgColor = data.blockSettings
    ? data.blockSettings.bg
      ? data.blockSettings.bg
      : false
    : false || false;
  const sx = {
    backgroundImage: `url(${typeof bgImg === "string" ? bgImg : bgImg.childImageSharp.fluid.src})`,
    position: "relative",
    ">*": {
      position: "relative",
      zIndex: 1
    }
  };
  sx[":before"] = {
    content: '""',
    position: "absolute",
    zIndex: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backgroundImage:
      "radial-gradient(circle, " +
      theme.colors.primaryHover +
      "99 0%, " +
      theme.colors.primaryHover +
      "99 100%)"
  };
  const containerProps = {};
  const wrapperProps = {};
  if (fullHeightActived) {
    if (data.blockSettings.padding) {
      containerProps.pb = data.blockSettings.padding.bottom;
      containerProps.pt = data.blockSettings.padding.top;
    }
    wrapperProps.pb = 0;
    wrapperProps.pt = 0;
  }
  return (
    <Wrapper
      data={data}
      sx={sx}
      containerProps={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        ...containerProps
      }}
      display="flex"
      {...wrapperProps}
    >
      <Box>
        <Heading as="h1" variant="h1">
          {data.bigTitle}
        </Heading>
        {data.subTitle && <Text color="white">{data.subTitle}</Text>}
        {data.ctas &&
          data.ctas.map((cta, i) => (
            <Link href={cta.href} key={i}>
              <Button>{cta.label}</Button>
            </Link>
          ))}
      </Box>
    </Wrapper>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    bigTitle: PropTypes.any,
    blockSettings: PropTypes.shape({
      bg: PropTypes.any,
      bgImg: PropTypes.any,
      fullHeight: PropTypes.any,
      padding: PropTypes.shape({
        bottom: PropTypes.any,
        top: PropTypes.any
      })
    }),
    ctas: PropTypes.array,
    subTitle: PropTypes.any
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primaryHover: PropTypes.any
    })
  })
};

export default withTheme(Header);
export const query = graphql`
  fragment HeaderBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    bigTitle
    subTitle
    ctas {
      href
      icon
      label
    }
  }
`;
