import PropTypes from "prop-types";
import React, { useState } from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import CustomImage from "../../UI/CustomImage";
import { Box, Text } from "rebass/styled-components";
import Modal from "../../UI/Modal";

const Gallery = ({ data }) => {
  const images = data.images || [];
  const [isOpen, toggle] = useState(false);

  function handlOpenModal(open) {
    toggle(open);
  }
  if (images.length > 0) {
    return (
      <Wrapper data={data}>
        <Grid
          childWidth={[1, 1 / 2, 1 / 3]}
          justifyContent={
            data.blockSettings.align === "center"
              ? "center"
              : data.blockSettings.align === "right"
              ? "flex-end"
              : ""
          }
        >
          {images.map((image, i) => {
            return (
              <Box key={"img-" + i} py={3}>
                <Box
                  onClick={() => handlOpenModal(i)}
                  height={["200px", "250px", "250px", "300px"]}
                  sx={{
                    cursor: "pointer",
                    overflow: "hidden",
                    position: "relative",
                    bg: "primaryLight",
                    "& > div, .gatsby-image-wrapper": {
                      width: "100%",
                      height: "100%"
                    }
                  }}
                >
                  <CustomImage img={image.image} />
                </Box>
                <Modal isOpen={i === isOpen} handleClose={() => handlOpenModal(false)}>
                  <Box
                    width="100vw"
                    height="80vh"
                    sx={{
                      "& > div, .gatsby-image-wrapper": {
                        width: "100%",
                        height: "100%",
                        img: {
                          objectFit: "contain !important"
                        }
                      }
                    }}
                  >
                    <CustomImage className="img-lightbox" img={image.image} />
                  </Box>
                </Modal>
              </Box>
            );
          })}
        </Grid>
      </Wrapper>
    );
  }
  return <></>;
};

Gallery.propTypes = {
  data: PropTypes.shape({
    blockSettings: PropTypes.shape({
      align: PropTypes.string
    }),
    images: PropTypes.array
  })
};
export default Gallery;
export const query = graphql`
  fragment GalleryBlock on MarkdownRemarkFrontmatterBlockscollection {
    images {
      alt
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
