import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import CustomImage from "../../UI/CustomImage";
import Wrapper from "../Wrapper";

const Image = ({ data }) => {
  return (
    <Wrapper data={data}>
      <CustomImage img={data.image} />
    </Wrapper>
  );
};

Image.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.any
  })
};
export default Image;

export const query = graphql`
  fragment ImageBlock on MarkdownRemarkFrontmatterBlockscollection {
    image {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
