import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import { Box, Heading } from "rebass/styled-components";
import Markdown from "../../UI/Markdown";

const Features = ({ data }) => {
  data.items = data.items || [];
  return (
    <Wrapper data={data}>
      <Grid childWidth={[1, 1 / 2, 1 / 2, 1 / data.items.length]}>
        {data.items.map((item, i) => (
          <Box key={"feat-" + i} py={3} height="100%">
            <Box
              sx={{
                py: 6,
                px: 4,
                pt: "75px",
                height: "100%",
                borderRadius: 4,
                bg: "transparent",
                borderStyle: "solid",
                borderColor: "primaryLight",
                borderWidth: "1px",
                textAlign: "center",
                position: "relative",
                "&:before": {
                  content: '"' + (i + 1) + '"',
                  fontFamily: "heading",
                  fontSize: 11,
                  fontWeight: "900",
                  lineHeight: "1",
                  color: "primary",
                  opacity: "0.1",
                  position: "absolute",
                  top: 6,
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 1
                },
                "& > div": {
                  position: "relative",
                  zIndex: 2
                }
              }}
            >
              <Heading as="h3" variant="body">
                {item.title}
              </Heading>
              <Box mt={2}>
                <Markdown>{item.content}</Markdown>
              </Box>
            </Box>
          </Box>
        ))}
      </Grid>
    </Wrapper>
  );
};

Features.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.array
  })
};
export default Features;

export const query = graphql`
  fragment FeaturesBlock on MarkdownRemarkFrontmatterBlockscollection {
    items {
      content
      title
    }
  }
`;
