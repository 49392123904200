import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import Wrapper from "../Wrapper";
import Card from "../../UI/Card";
import Link from "../../UI/Link";
import Accordion from "../../UI/Accordion";
import Icon from "../../UI/Icon";
import CardSlider from "./CardSlider";

const Cards = ({ data }) => {
  data.cards = data.cards || [];
  if (data.style === "slider") {
    return (
      <Wrapper data={data}>
        <Box mx={[-2, -2, -5]}>
          <CardSlider slidesToShow={parseInt(data.nbCards)}>
            {data.cards.map((card, i) => {
              return (
                <Box key={"card-" + i} p={[2, 2, 5]}>
                  <Card isVertical={data.cardFormat === "vertical"} {...card} />
                </Box>
              );
            })}
          </CardSlider>
        </Box>
      </Wrapper>
    );
  }
  if (data.style === "accordion") {
    return (
      <Wrapper data={data}>
        <Box>
          <Accordion
            items={data.cards.map((card, i) => {
              return {
                title: card.title,
                content: (
                  <Box key={"card-" + i} py={[2, 2, 5]}>
                    <Card isVertical={data.cardFormat === "vertical"} {...card} />
                  </Box>
                )
              };
            })}
          ></Accordion>
        </Box>
      </Wrapper>
    );
  }
  return (
    <Wrapper data={data}>
      <Flex
        variant="grid"
        justifyContent={
          data.blockSettings.align === "center"
            ? "center"
            : data.blockSettings.align === "right"
            ? "flex-end"
            : ""
        }
      >
        {data.cards.map((card, i) => {
          return (
            <Box
              variant="gridItem"
              key={"card-" + i}
              width={[1, 1 / 2, 1 / parseInt(data.nbCards)]}
              pb={6}
            >
              <Card
                isVertical={data.cardFormat === "vertical"}
                imageOnRight={i % 2 !== 0 && data.style === "listAltenate"}
                {...card}
              />
            </Box>
          );
        })}
      </Flex>
      {data.cta && (
        <Flex justifyContent="center" mt={4}>
          <Link href={data.cta.href}>
            <Button variant="base">
              <Text>{data.cta.ctaLabel}</Text>
              <Icon icon="arrow-small-next" size={14} />
            </Button>
          </Link>
        </Flex>
      )}
    </Wrapper>
  );
};

Cards.propTypes = {
  data: PropTypes.shape({
    blockSettings: PropTypes.shape({
      align: PropTypes.string
    }),
    cards: PropTypes.array,
    nbCards: PropTypes.any,
    style: PropTypes.string,
    cardFormat: PropTypes.string,
    cta: PropTypes.any
  })
};
export default Cards;

export const query = graphql`
  fragment CardsBlock on MarkdownRemarkFrontmatterBlockscollection {
    cards {
      picto {
        publicURL
        childImageSharp {
          fluid(maxWidth: 400, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content
      title
      link
      linklabel
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 400, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    style
    cardFormat
    nbCards
    cta {
      ctaLabel
      href
    }
  }
`;
