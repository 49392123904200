import PropTypes from "prop-types";
import React from "react";
import Slider from "../../UI/Slider";

const CardSlider = ({ children, slidesToShow }) => {
  var settings = {
    dots: true,
    autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: 1
        }
      }
    ]
  };
  return <Slider {...settings}>{children}</Slider>;
};

CardSlider.propTypes = {
  slidesToShow: PropTypes.number,
  children: PropTypes.node
};

CardSlider.defaultProps = {
  slidesToShow: 4
};
export default CardSlider;
