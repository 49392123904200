import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { Flex, Box, Heading } from "rebass/styled-components";
import Wrapper from "../Wrapper";
import CustomImage from "../../UI/CustomImage";
import Slider from "../../UI/Slider";
import Link from "../../UI/Link";
import Icon from "../../UI/Icon";

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <Box
      className={className}
      onClick={onClick}
      sx={{
        color: "white",
        "&:hover": {
          color: "secondary"
        }
      }}
    >
      <Icon icon="arrow-next" size={26} />
    </Box>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <Box
      className={className}
      onClick={onClick}
      sx={{
        color: "white",
        "&:hover": {
          color: "secondary"
        }
      }}
    >
      <Icon icon="arrow-prev" size={26} />
    </Box>
  );
}

const Slideshow = ({ data }) => {
  data.slides = data.slides || [];
  var settings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };
  return (
    <Wrapper
      data={data}
      sx={{
        "& > div": {
          px: [0, 0, 0],
          height: "100%",
          bg: "primaryDark"
        },
        ".slick-slider": {
          height: "100vh",
          ".slick-track, .slick-list": {
            height: "100%"
          },
          ".slick-track": {
            display: "block",
            ".slick-slide": {
              position: "relative",
              "& > div": {
                height: "100%",
                "& > div": {
                  height: "100%",
                  "& > div": {
                    height: "100%"
                  }
                }
              }
            }
          },
          ".slick-arrow": {
            zIndex: 10,
            right: "inherit",
            height: "auto",
            width: "auto",
            transform: "none",
            top: ["inherit", "50%"],
            bottom: [6, "inherit"],
            "&:before": {
              display: "none"
            },
            "&.slick-prev": {
              left: [3, 3, 6]
            },
            "&.slick-next": {
              right: [3, 3, 6]
            }
          },
          ".slick-dots": {
            position: "absolute",
            bottom: 8,
            display: "flex !important",
            justifyContent: "center",
            alignItems: "center",
            "& > li": {
              display: "block",
              width: "45px",
              height: "auto",
              opacity: "0.3",
              transition: "all 300ms ease",
              button: {
                width: "100%",
                height: "8px",
                bg: "white",
                p: 0,
                transition: "all 300ms ease",
                "&:before": {
                  display: "none"
                },
                "&:hover": {
                  height: "10px"
                }
              },
              "&.slick-active": {
                opacity: "1",
                button: {
                  height: "10px"
                }
              }
            }
          }
        }
      }}
    >
      <Slider {...settings}>
        {data.slides.map((slide, i) => (
          <Box key={"slide-" + i}>
            <Flex
              alignItems="center"
              justifyContent="center"
              sx={{
                position: "relative",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                  backgroundImage: "url(" + slide.imageBG.childImageSharp.fluid.srcWebp + ")",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: "0.4"
                }
              }}
            >
              <Box
                textAlign="center"
                sx={{
                  position: "relative",
                  zIndex: 2
                }}
              >
                {slide.logo && (
                  <Box maxWidth={["100px", "120px", "150px"]} mx="auto" mb="8">
                    <CustomImage img={slide.logo} />
                  </Box>
                )}
                {slide.title1 && (
                  <Heading as="h2" variant="hero" fontWeight="normal">
                    {slide.title1}
                  </Heading>
                )}
                {slide.title2 && (
                  <Flex justifyContent="center">
                    <Box
                      sx={{
                        position: "relative",
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          bottom: 0,
                          left: "50%",
                          transform: "translateX(-50%)",
                          width: "90%",
                          height: "7px",
                          bg: "secondary",
                          zIndex: 1
                        },
                        "& > *": {
                          position: "relative",
                          zIndex: 2
                        }
                      }}
                    >
                      <Heading as="h2" variant="hero">
                        {slide.title2}
                      </Heading>
                    </Box>
                  </Flex>
                )}
              </Box>
            </Flex>
            {slide.href && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 3,
                  a: {
                    display: "block",
                    width: "100%",
                    height: "100%",
                    textIndent: "-99999px",
                    overflow: "hidden"
                  }
                }}
              >
                <Link href={slide.href}>En savoir plus</Link>
              </Box>
            )}
          </Box>
        ))}
      </Slider>
    </Wrapper>
  );
};

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.any
};
PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.any
};
Slideshow.propTypes = {
  data: PropTypes.shape({
    slides: PropTypes.array
  })
};
Slideshow.defaultProps = {
  data: {
    slides: []
  }
};

export default Slideshow;

export const query = graphql`
  fragment SlideshowBlock on MarkdownRemarkFrontmatterBlockscollection {
    slides {
      title1
      title2
      href
      logo {
        publicURL
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageBG {
        publicURL
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
