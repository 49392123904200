import React from "react";
import Blocks from "../../components/Blocks";
import PropTypes from "prop-types";

const PageTemplate = ({ data }) => {
  return <Blocks blocks={data.blockscollection} />;
};
PageTemplate.propTypes = {
  data: PropTypes.object
};
export default PageTemplate;
