import { graphql } from "gatsby";
import ImgMarkupTwoCol from "./ImgMarkupTwoCol";

export default ImgMarkupTwoCol;

export const query = graphql`
  fragment ImgMarkupTwoColBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    rows {
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contentTitle
      content
      textPosition
      patternImg
      cta {
        label
        href
      }
    }
  }
`;
