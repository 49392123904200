import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import { Box, Heading, Flex, Text, Button } from "rebass/styled-components";
import Forms from "../../Forms";
import Map from "../../UI/Map";
import Icon from "../../UI/Icon";

function isString(str) {
  if (str && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
}

const FormMap = ({ data }) => {
  const hasData = data.form ? !isString(data.form) : false;
  const fluidContainer = data.blockSettings ? data.blockSettings.containerFluid : false;
  var telLink = "tel:+687";
  var mailTo = "mailto:";
  return (
    <Wrapper
      data={data}
      containerProps={{
        alignItems: "stretch",
        display: "flex !important",
        px: fluidContainer ? "10px !important" : "",
        sx: { ".container-inner": { width: "100% !important" } }
      }}
    >
      {hasData && (
        <Flex variant="grid" className="test">
          <Flex
            variant="gridItem"
            alignItems="center"
            width={[1, 1, 1 / 2, 3 / 5, 2 / 3]}
            px={fluidContainer ? [3, 3, 6] : ""}
          >
            <Flex
              alignItems="center"
              justifyContent={"center"}
              pl={fluidContainer ? [0, 0, 5] : ""}
            >
              <Forms data={data.form.frontmatter} />
            </Flex>
          </Flex>
          <Box
            variant="gridItem"
            width={[1, 1, 1 / 2, 2 / 5, 1 / 3]}
            px={fluidContainer ? [3, 3, 6] : ""}
          >
            <Box
              mt={[6, 6, 0]}
              sx={{
                position: "relative",
                boxShadow: "default",
                borderRadius: "default",
                overflow: "hidden",
                bg: "white",
                a: {
                  textDecoration: "underline",
                  "&:hover div + div": {
                    bg: "secondary"
                  }
                }
              }}
              pr={fluidContainer ? [0, 0, 6] : ""}
            >
              <Box height="250px" sx={{ position: "relative" }}>
                <Map position={data.position} />
              </Box>
              {data.showInfo && (
                <Box p={6}>
                  {data.cardTitle && (
                    <Heading as="h3" variant="h5" mb={4} sx={{ textTransform: "uppercase" }}>
                      {data.cardTitle}
                    </Heading>
                  )}

                  {/* {data.email && (
                    <a href={mailTo + data.email}>
                      <Flex alignItems="center">
                        <Flex alignItems="center" color="secondary" pr={2}>
                          <Icon icon="mail" size={20} />
                        </Flex>
                        <Text>{data.email}</Text>
                      </Flex>
                    </a>
                  )} */}
                  <Flex flexDirection={("column", "row")}>
                    {data.phone && (
                      <a
                        href={
                          telLink +
                          (data.phone
                            ? data.phone.replace(/[- )(]/g, "").replace("+687", "")
                            : data.phone.replace("+687", ""))
                        }
                      >
                        <Flex alignItems="center" mt={4}>
                          <Flex alignItems="center" color="secondary" pr={2}>
                            <Icon icon="phone" size={20} />
                          </Flex>
                          <Text>{data.phone}</Text>
                        </Flex>
                      </a>
                    )}
                    {/* <Box ml={2} mt={4}>
                      <Text>/</Text>
                    </Box>
                    {data.phone2 && (
                      <a
                        href={
                          telLink +
                          (data.phone2
                            ? data.phone2.replace(/[- )(]/g, "").replace("+687", "")
                            : data.phone2.replace("+687", ""))
                        }
                      >
                        <Flex alignItems="center" mt={4}>
                          <Flex alignItems="center" color="secondary" pr={2}></Flex>
                          <Text>{data.phone2}</Text>
                        </Flex>
                      </a>
                    )} */}
                  </Flex>
                  {data.address && (
                    <Box mt={4}>
                      <Flex>
                        <Box color="secondary" pr={2} pt={1}>
                          <Icon icon="poi" size={20} />
                        </Box>
                        <Box sx={{ "p:first-child": { mt: 0 }, "p:last-child": { mb: 0 } }}>
                          <Markdown>{data.address}</Markdown>
                        </Box>
                      </Flex>
                      <Box
                        mt={4}
                        sx={{
                          a: {
                            textDecoration: "none"
                          }
                        }}
                      >
                        <a
                          href="https://goo.gl/maps/eD7LxRnGdp54Nb7H9"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button variant="base" width="100%">
                            <Text>Itinéraire via Google Map</Text>
                            <Icon icon="link-external" size={14} />
                          </Button>
                        </a>
                      </Box>
                    </Box>
                  )}
                  {data.openTime && (
                    <Flex mt={4}>
                      <Box color="secondary" pr={2} pt={1}>
                        <Icon icon="clock" size={20} />
                      </Box>
                      <Box sx={{ "p:first-child": { mt: 0 }, "p:last-child": { mb: 0 } }}>
                        <Markdown>{data.openTime}</Markdown>
                      </Box>
                    </Flex>
                  )}
                  {data.socials.facebook && (
                    <Flex
                      mt={4}
                      justifyContent="center"
                      sx={{
                        a: {
                          display: "block",
                          lineHeight: "1"
                        }
                      }}
                    >
                      <span style={{ paddingRight: "5px" }}>Visitez notre </span><a href={data.socials.facebook} target="_blank" rel="noreferrer">
                        <Icon icon="facebook" size={30} />
                      </a>
                    </Flex>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Flex>
      )}
      {!hasData && (
        <Box>
          <Text>Formulaire + carte Non disponible en previsualisation</Text>
        </Box>
      )}
    </Wrapper>
  );
};

FormMap.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.any,
    blockSettings: PropTypes.shape({
      containerFluid: PropTypes.any
    }),
    cardTitle: PropTypes.any,
    openTime: PropTypes.any,
    email: PropTypes.any,
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    }),
    phone: PropTypes.any,
    phone2: PropTypes.any,
    position: PropTypes.any,
    showInfo: PropTypes.any,
    socials: PropTypes.any
  })
};
export default FormMap;

export const query = graphql`
  fragment FormMapBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        successMessage
        elements {
          imputType
          label
          name
          printLabel
          placeholder
          required
          type
          default
          multiple
          options {
            value
            label
          }
        }
      }
    }
    position
    address
    openTime
    cardTitle
    phone
    phone2
    email
    showInfo
    socials {
      facebook
      instagram
      youtube
      twitter
    }
  }
`;
