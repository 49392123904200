import PropTypes from "prop-types";
import React from "react";
import Container from "./Container";
import { Box, Flex } from "rebass/styled-components";
import { Link } from "gatsby";
import HomeIcon from "../../../static/img/icons/home.svg";

const CustomBreadcrumb = ({ crumbLabel, breadcrumb }) => {
  return (
    <Container>
      {breadcrumb.crumbs.length > 0 && (
        <Box as="nav" py={3}>
          <Flex alignItems="center" as="ul" p={0} sx={{ listStyleType: "none" }}>
            {breadcrumb.crumbs.map((crumb, i) => {
              let isLast = i === breadcrumb.crumbs.length - 1;
              let label = isLast && crumbLabel ? crumbLabel : crumb.crumbLabel;
              let icon = (
                <Flex alignItems="center" mr={2}>
                  <HomeIcon
                    icon="home"
                    fill="currentColor"
                    stroke="currentColor"
                    height={"16px"}
                    width={"16px"}
                  />
                </Flex>
              );
              return (
                <React.Fragment key={i}>
                  {!isLast && (
                    <Flex alignItems="center" as="li">
                      <Link to={crumb.pathname} title={label}>
                        <Flex>
                          {i === 0 ? icon : false}
                          {label}
                        </Flex>
                      </Link>
                    </Flex>
                  )}
                  {isLast && (
                    <Flex alignItems="center" as="li">
                      {label}
                    </Flex>
                  )}
                  {!isLast && (
                    <Flex alignItems="center" px={2} as="span">
                      /
                    </Flex>
                  )}
                </React.Fragment>
              );
            })}
          </Flex>
        </Box>
      )}
    </Container>
  );
};

CustomBreadcrumb.propTypes = {
  breadcrumb: PropTypes.object,
  crumbLabel: PropTypes.string
};

CustomBreadcrumb.defaultProps = {
  breadcrumb: {
    crumbs: []
  },
  crumbLabel: ""
};

export default CustomBreadcrumb;
