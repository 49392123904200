import React from "react";
import PropTypes from "prop-types";
import VerticalCard from "./VerticalCard";
import HorizontalCard from "./HorizontalCard";

const Card = ({ isVertical, ...props }) => {
  isVertical = isVertical || false;
  return isVertical ? <VerticalCard {...props} /> : <HorizontalCard {...props} />;
};

Card.propTypes = {
  isVertical: PropTypes.bool
};

export default Card;
