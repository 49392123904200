import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";
import { Box } from "rebass/styled-components";

const Markup = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Box maxWidth="700px" mx="auto">
        <Markdown>{data.content}</Markdown>
      </Box>
    </Wrapper>
  );
};

Markup.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
    title: PropTypes.any
  })
};
export default Markup;

export const query = graphql`
  fragment MarkupBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
  }
`;
