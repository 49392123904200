import PropTypes from "prop-types";
import React from "react";
import Markdown from "../Markdown";
import Link from "../Link";
import CustomImage from "../CustomImage";
import { Box, Heading } from "rebass/styled-components";

const VerticalCard = ({ picto, title, content, image, link, linklabel }) => {
  const hasLinnk = link !== null && link !== "" && linklabel !== null && linklabel !== "";
  return (
    <Box variant="verticalCard" height="100%">
      {image && (
        <>
          <Box
            width={1}
            mb={4}
            height="300px"
            sx={{
              backgroundImage: `url(${
                typeof image === "string" ? image : image.childImageSharp.fluid.src
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
          ></Box>
          <Box>
            {title && <Heading as="h3">{title}</Heading>}
            {content && <Markdown>{content}</Markdown>}
            {hasLinnk && (
              <Box py={2} width={[1]}>
                <Link href={link}>{linklabel}</Link>
              </Box>
            )}
          </Box>
        </>
      )}
      {!image && (
        <Box>
          {picto && (
            <Box maxWidth="50px" maxHeight="50px" mb={4}>
              <CustomImage img={picto} />
            </Box>
          )}
          {title && (
            <Heading as="h3" variant="h3">
              {title}
            </Heading>
          )}
          {content && (
            <Box mt={3}>
              <Markdown>{content}</Markdown>
            </Box>
          )}
          {hasLinnk && (
            <Box py={2} width={[1]}>
              <Link href={link}>{linklabel}</Link>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

VerticalCard.propTypes = {
  content: PropTypes.any,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.any
      })
    })
  }),
  link: PropTypes.string,
  linklabel: PropTypes.string,
  title: PropTypes.any,
  picto: PropTypes.any
};

export default VerticalCard;
