import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import Link from "../../UI/Link";
import Icon from "../../UI/Icon";
import { Flex, Box, Heading, Button, Text } from "rebass/styled-components";
import { withTheme } from "styled-components";

const Banner = ({ data, theme }) => {
  return (
    <Wrapper data={data} py={[0, 0, 0, 0]}>
      <Flex
        py={[13, 13, 13, 16]}
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        variant="grid"
      >
        <Box width={[1, 1, 1, 3 / 5]} variant="gridItem" sx={{ "p:last-child": { mb: 0 } }}>
          <Flex flexDirection="column" justifyContent="center" height="100%" color="white">
            {data.title && (
              <Box maxWidth="450px">
                <Heading as="h2" variant="h2" color="white">
                  {data.title}
                </Heading>
              </Box>
            )}
            {data.content && (
              <Box mt={[4, 4, 6]} maxWidth="350px">
                <Markdown>{data.content}</Markdown>
              </Box>
            )}
          </Flex>
        </Box>
        <Flex
          mt={[4, 4, 6, 0]}
          width={[1, 1, 1, 2 / 5]}
          justifyContent={["flex-start", "flex-start", "flex-start", "flex-end"]}
          alignItems="center"
          variant="gridItem"
        >
          <Link href={data.cta.href}>
            <Button variant="baseTrans">
              <Text>{data.cta.label}</Text>
              <Icon icon="arrow-small-next" size={14} />
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

Banner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    content: PropTypes.any,
    cta: PropTypes.any
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primaryHover: PropTypes.any
    })
  })
};

export default withTheme(Banner);

export const query = graphql`
  fragment BannerBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    content
    cta {
      href
      label
    }
  }
`;
