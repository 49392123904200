import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";

import { Box, Heading } from "rebass/styled-components";
const AccordionItem = ({ i, expanded, setExpanded, children, title }) => {
  const isOpen = i === expanded;
  const accRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (accRef !== null) {
      setHeight(accRef.current.clientHeight);
    }
  }, [accRef, expanded]);
  return (
    <>
      <Box
        sx={{
          zIndex: 1,
          position: "relative",
          cursor: "pointer"
        }}
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        <Heading as="h3">{title}</Heading>
      </Box>
      <Box
        sx={{
          zIndex: 0,
          position: "relative",
          height: isOpen ? height : 0,
          transition: "300ms all ease",
          opacity: isOpen ? 1 : 0
        }}
      >
        <Box
          ref={accRef}
          sx={{
            opacity: isOpen ? 1 : 0,
            height: isOpen ? "auto" : 0,
            transition: "300ms all ease"
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

AccordionItem.propTypes = {
  children: PropTypes.any,
  expanded: PropTypes.any,
  i: PropTypes.any,
  setExpanded: PropTypes.func,
  title: PropTypes.any
};

const Accordion = ({ items }) => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(0);
  return (
    <Box>
      {items.map((item, i) => {
        return (
          <AccordionItem
            key={i}
            i={i}
            expanded={expanded}
            setExpanded={setExpanded}
            title={item.title}
          >
            {item.content}
          </AccordionItem>
        );
      })}
    </Box>
  );
};

Accordion.propTypes = {
  items: PropTypes.array
};
export default Accordion;
